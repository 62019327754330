import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/cart": [~4],
		"/cart/cashdesk": [~5],
		"/cart/delivery": [~6],
		"/cart/ordered": [~7],
		"/cart/shipping-address": [~8],
		"/order-info/[order_id]/key/[code]": [~9],
		"/pages/[url]": [~12],
		"/payments/gpe/error": [13],
		"/payments/gpe/ok": [14],
		"/p/[url]": [~10],
		"/p/[url]/gallery": [~11],
		"/search/[[query]]": [~15],
		"/session": [~16],
		"/sizechart/[id]/[...readable]": [~17],
		"/user/login": [18],
		"/user/logout": [~19],
		"/user/profile": [~20],
		"/user/register": [21],
		"/[...magic]": [~3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';